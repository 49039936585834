/* CHECKOUT PAGE */



.woocommerce-checkout {
    .woocommerce {
        margin-top: 50px;
    }
    #order_review {
        margin-top: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        table {
            flex-basis: 48%;
            color: #000;
            background-color: #fff;
            box-shadow: 0px 4px 12px rgba(86, 86, 86, 0.05);
        }
        #payment {
            flex-basis: 48%;
            background-color: #fff;
            box-shadow: 0px 4px 12px rgba(86, 86, 86, 0.05);
        }
    }

    #order_review_heading {
        font-weight: bold;
        font-size: 36px;
        line-height: 38px;
        color: #000;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .woocommerce-billing-fields {
        h3 {
            font-weight: bold;
            font-size: 36px;
            line-height: 42px;
            color: #262626;
            margin-bottom: 15px;
            text-align: center;
            display: none;
        }
        .woocommerce-input-wrapper {
            max-width: 300px;
            width: 100%;
        }
        input {
            width: 100%;
            max-width: 420px;
            height: 36px;
            background: #FFFFFF;
            border: 1px solid rgba(38, 59, 121, 0.1);
            box-sizing: border-box;
            margin-bottom: 20px;
            outline: none;
            padding-left: 20px;
        }
        label {
            font-size: 12px;
            line-height: 14px;
            color: rgba(38, 59, 121, 0.4);
            margin-bottom: 4px;
            width: 100%;
            cursor: pointer;
        }
        #billing_email_field {
            display: flex;
            flex-wrap: wrap;
        }
    }

    #place_order {
        display: block;
        background-color: #000;
        border: none;
        padding: 15px 42px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        width: auto;
        height: auto;
        margin-top: 10px;
    }
}


@media screen and (max-width: 767px) {
    .woocommerce-checkout {
        #order_review {
            flex-wrap: wrap;
            table {
                flex-basis: 100%;
            }
            #payment {
                flex-basis: 100%;
            }
        }
    }
}
/* END OF CHECKOUT PAGE */