@import "_libs.scss";
@import "_checkout_page.scss";

// @media (min-width: 1260px) {
//     .container, .container-sm, .container-md, .container-lg, .container-xl {
//         max-width: 1200px;
//     }
// }

%h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 88px;
  line-height: 114px;
}
%h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 73px;
}
%h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}
%h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
}
%h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
}
%body1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.005em;
}
%body2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.005em;
}
%body3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}
%navigation_font {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
}

$main_color: #fff;
$gray1: #C0C9D0;
$gray2: #71787C;
$border_linear: linear-gradient(90.26deg, #D8DDE1 4.82%, rgba(203, 206, 210, 0.11) 99.79%);
$border_linear2: linear-gradient(90.26deg, #595959 4.82%, rgba(203, 206, 210, 0.11) 99.79%);
$bg_color: #0A0A0A;
$black2: #1E1E22;
$main_linear: linear-gradient(51.7deg, #1E1F23 18.23%, #1A1C20 50.04%, #07070A 84.51%);
$secondary_linear: linear-gradient(62.89deg, #1F1F24 18.5%, #141518 46.25%, #0E1015 76.32%);

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

body {
  font-family: 'Raleway', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $bg_color;
  overflow-x: hidden;
}

main {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}


canvas {
  pointer-events: none;
}


.wrapper {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // background-color: red;
  .column {
    width: 100%;
    max-width: 368px;
    display: flex;
    flex-wrap: wrap;
    // background-color: silver;
  }
  .column.text-right {
    justify-content: flex-end;
  }
  .column.text-center {
    justify-content: center;
  }
  .column.a-center {
    align-items: center;
  }
  .column-2 {
    width: 100%;
    max-width: 784px;
  }
  .column-3 {
    width: 100%;
  }
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}


.header_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  transform-style: preserve-3d;

  .for_logo {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 10px;
    text-decoration: none;
    span {
      position: relative;
      z-index: 333;
    }
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 72px;
      height: 72px;
      // border-radius: 50%;
      // border: 1px solid #fff;
      position: absolute;
      top: -18px;
      left: -20px;
      background: $border_linear;
      border-radius: 50%;
      animation: rotation 5s ease infinite;
    }
    &::after {
      content: '';
      display: block;
      width: 69px;
      height: 69px;
      background-color: $bg_color;
      border-radius: 50%;
      position: absolute;
      top: -16.5px;
      left: -19px;
    }
  }

  ul {
    li {
      display: inline-block;
      margin-right: 33px;
      &:last-child {
        margin-right: 0px;
      }
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: $gray1;
        position: relative;
        text-decoration: none;
        &::before {
          transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.2s;
          content: '';
          display: block;
          width: 0%;
          height: 1px;
          background-color: #fff;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
        }
        &:hover {
          &::before {
            width: 60%;
          }
        }
      }
    }
  }
}


// .place_for_canvas {
//   margin-top: 80px;
//   margin-bottom: 65px;
//   min-height: 720px;
//   background-color: silver;
// }
.place_for_canvas {
  min-height: 720px;
}


.homepage_second_screen {
  margin-bottom: 100px;
  .block {
    margin: 0px auto;
    width: 280px;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    p {
      @extend %h3;
      position: relative;
      z-index: 33;
      color: #fff;
    }
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background: $border_linear;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      animation: rotation 5s ease infinite;
    }
    &::after {
      content: '';
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      top: 1px;
      left: 1px;
      position: absolute;
      background: $bg_color;
      border-radius: 50%;
    }
  }

  .column {
    &:nth-child(2) {
      .block {
        margin-top: 232px;
        &::before {
          animation-delay: 1s;
        }
      }
    }
    &:nth-child(3) {
      .block {
        margin-top: 71px;
        &::before {
          animation-delay: 2s;
        }
      }
    }
  }
}

.we_know_place_for_canvas {
  position: relative;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.we_know {
  position: relative;
  z-index: 33;
  padding-bottom: 375px;
  padding-top: 364px;
  color: #fff;
  .inherit_title {
    @extend %h3;
    margin-bottom: 56px;
    &+p {
      @extend %body2;
      margin-bottom: 128px;
    }
  }

  .step {
    margin-bottom: 67px;
    &:last-child {
      margin-bottom: 0px;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      @extend %navigation_font;
      display: inline-block;
      margin-right: 10px;
    }
    p {
      @extend %h3;

    }
  }
}


.achievements {
  position: relative;
  z-index: 33;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  padding-bottom: 450px;
  margin-bottom: 82px;
  padding-top: 100px;
  color: #fff;
  .inherit_title {
    @extend %h3;
    margin-bottom: 27px;
    &+p {
      @extend %body2;
      margin-bottom: 43px;
      &+a {
        margin-bottom: 131px;
        @extend %navigation_font;
        color: #fff;
        text-decoration: none;
        svg {
          margin-left: 10px;
        }
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .inherit_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 100%;
      max-width: 232px;
      p {
        &:first-child {
          @extend %h2;
          text-align: center;
          margin-bottom: 31px;
          &+p {
            @extend %h5;
          }
        }
      }
    }
  }
}


.slider_container {
  position: relative;
  z-index: 33;
  margin-bottom: 431px;
  color: #fff;
  .inherit_title {
    @extend %h3;
    margin-bottom: 48px;
  }

  .slick-dots {
    bottom: -100px;
    li {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #242427;
      button {
        opacity: 0;
      }
    }
    .slick-active {
      background: #71787C;
    }
  }


  .slide_content {
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 576px;
      height: 273px;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 314px;
        height: 314px;
        border-radius: 50%;
        background: $main_linear;
        opacity: 0.6;
        position: absolute;
        left: 0;
        top: -41px;
        z-index: -1;
      }
      .left {
        margin-left: 56px;
        margin-top: -40px;
      }
      .right {
        max-width: 312px;
        margin-left: 40px;
        margin-right: 48px;
        p {
          &:first-child {
            @extend %h5;
            margin-bottom: 1px;
            min-height: 72px;
            &+p {
              min-height: 104px;
              @extend %body2;
              margin-bottom: 33px;
              &+p {
                @extend %body3;
              }
            }
          }
        }
      }
    }
  }
}


.pre_footer_block {
  position: relative;
  z-index: 33;
  margin-bottom: 235px;
  color: #fff;
  .column {
    p {
      width: 100%;
      // max-width: 224px;
      color: #fff;
      margin-bottom: 35px;
      margin-top: 30px;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
      text-decoration: none;
      @extend %navigation_font;
      svg {
        margin-left: 8px;
      }
    }
  }

  .column-2 {
    p {
      &:first-child {
        @extend %h3;
        margin-bottom: 22px;
        &+p {
          @extend %body1;
        }
      }
    }
  }
}


footer {
  padding-bottom: 21px;
  a {
    text-decoration: none;
  }
  .column-2 {
    .for_ul {
      display: flex;
      justify-content: flex-start;
      ul {
        margin-right: 40px;
      }
      // max-width: 454px;
    }
    ul {
      // columns: 4;
      // max-width: 500px;
      li {
        margin-bottom: 11px;
        a {
          @extend %body3;
          color: $gray2;
          text-decoration: none;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .column-3 {
    margin-bottom: 55px;
  }
  .column {
    @extend %body3;
    color: $gray2;
    p {
      &:hover {
        color: #fff;
      }
    }
    a {
      color: $gray2;
      &:hover {
        color: #fff;
      }
    }
  }
}

.about_wrapper {
  margin-top: 112px;
  color: #fff;
  .about_title {
    @extend %h2;
  }
  .about_descr {
    @extend %body1;
    margin-bottom: 230px;
  }
}
.about_who_fluid {
  position: relative;
  .for_about_canvas {
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  canvas {
    // position: absolute;
    // left: 0;
    // top: 0;
  }
}
.about_who_we {
  margin-bottom: 391px;
  .column {
    position: relative;
    
  }
  .column-2 {
    position: relative;
    z-index: 33;
  }
  p {
    color: #fff;
    &:first-child {
      @extend %h4;
      margin-bottom: 35px;
      &+p {
        @extend %body2;
        margin-bottom: 45px;
        &+p {
          @extend %body2;
          margin-bottom: 60px;
        }
      }
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      margin-left: 8px;
    }
    color: #fff;
    @extend %navigation_font;
  }
}

.about_offer {
  color: #fff;
  margin-bottom: 325px;
  .column-2 {
    p {
      &:first-child {
        @extend %h4;
        margin-bottom: 38px;
        &+p {
          @extend %body1;
          margin-bottom: 97px;
        }
      }
    }
  }

  .column-3 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    div {
      width: 100%;
      max-width: 232px;
      p {
        &:first-child {
          @extend %h2;
          margin-bottom: 27px;
          &+p {
            @extend %body1;
          }
        }
      }
    }
  }
}


.faq_banner {
  margin-top: 105px;
  margin-bottom: 366px;
  color: #fff;
  .faq_title {
    @extend %h2;
  }
  .faq_descr {
    @extend %body2;
    margin-top: 30px;
  }
}

.questions_container {
  margin-bottom: 308px;
  color: #fff;
  .questions_title {
    @extend %h3;
  }


  .question_block {
    cursor: pointer;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0px;
    }
    &.active {
      .question {
        &::before {
          transform: rotate(90deg);
        }
      }
    }
  }

  .question {
    @extend %h5;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-image: url('../img/fa_question.svg');
      position: absolute;
      top: 3px;
      left: -32px;
      transition: 0.2s ease;
    }
  }

  .answer {
    @extend %body1;
    margin-top: 32px;
    display: none;
  }
}


.approach {
  margin-bottom: 370px;
  color: #fff;
  .inherit_title {
    @extend %h3;
    margin-bottom: 121px;
  }

  .column p,
  .column-2 p {
    margin-bottom: 55px;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.005em;
    color: #fff;
    padding-left: 56px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 122px;
      height: 122px;
      background: $border_linear2;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      z-index: -2;
    }
    &::after {
      content: '';
      display: block;
      width: 120px;
      height: 120px;
      position: absolute;
      left: 1px;
      top: 50%;
      transform: translateY(-50%);
      background-color: $bg_color;
      border-radius: 50%;
      z-index: -1;
    }
  }
}


.api_banner {
  color: #fff;
  margin-top: 112px;
  margin-bottom: 270px;
  .api_title {
    @extend %h2;
  }
  .api_descr {
    margin-top: 30px;
    @extend %body1;
  }
}

.api_content_container {
  // width: 100%;
  color: #fff;
  // background-color: #fff;
  // padding-top: 100px;
  // padding-bottom: 100px;
  margin-bottom: 184px;
  .api_content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1000px;
  
    pre {
      overflow: hidden;
      color: #fff;
    }
    p {
        margin: 10px 0px;
    }
    h2 {
        @extend %h2;
        margin: 20px 0px;
    }
    ul,ol {
        margin: 15px 0px;
        padding-left: 30px;
        li {
            margin: 10px 0px;
        }
    }
    ol {
        list-style-type: decimal;
    }
    ul {
        li {
            position: relative;
            &:before {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                position: absolute;
                left: -20px;
                top: 12px;
                background-color: #fff;
            }
        }
    }

    table tr, td{
        border: 1.5px solid #fff;
        padding: 5px 10px;
    }
    table {
        margin: 20px 0px;
    }
  }
}



.pricing_banner {
  margin-bottom: 757px;
  color: #fff;
  margin-top: 112px;
  .pricing_title {
    @extend %h2;
  }
  .pricing_descr {
    margin-top: 30px;
    @extend %body1;
  }
}


.pre_pricing {
  color: #fff;
  p {
    &:first-child {
      @extend %h3;
      margin-bottom: 34px;
      &+p {
        max-width: 366px;
        @extend %body1;
        margin-bottom: 178px;
        a {
          color: #fff;
          text-decoration: none;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 0%;
            height: 2px;
            background-color: #fff;
            position: absolute;
            bottom: -2px;
            left: 0;
            transition: 0.2s ease-in-out;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}



.category_title {
  @extend %h5;
  color: #fff;
  margin-bottom: 55px;
}

.products_flex {
  margin-bottom: 73px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .product {
    a {
      position: absolute;
      left: 57px;
      bottom: 65px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      @extend %navigation_font;
      text-decoration: none;
      color: #fff;
      svg {
        margin-left: 8px;
      }
      &::after {
        content: '';
        display: block;
        width: 89px;
        height: 89px;
        border-radius: 50%;
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
        background: #16161A;
        z-index: 1;
      }
      span {
        position: relative;
        z-index: 3;
      }
    }
    width: 100%;
    max-width: 368px;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: calc(100% + 26px);
      height: 100%;
      background: $secondary_linear;
      position: absolute;
      top: 0;
      left: -26px;
      z-index: -1;
      border-radius: 24px;
      opacity: 0;
      transition: 0.24s ease-in-out;
    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
    padding-top: 33px;
    padding-left: 57px;
    padding-bottom: 151px;
    p {
      &:first-child {
        max-width: 319px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        @extend %h5;
        margin-bottom: 23px;
        &::before {
          content: '';
          display: block;
          width: 94px;
          height: 94px;
          border-radius: 50%;
          background: $border_linear;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -31px;
        }
        &::after {
          content: '';
          display: block;
          width: 92px;
          height: 92px;
          border-radius: 50%;
          background-color: $bg_color;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -30px;
        }
        span {
          position: relative;
          z-index: 33;
        }
        &+p {
          margin-bottom: 40px;
          span {
            &:first-child {
              @extend %h4;
              color: #fff;
              &+span {
                @extend %body2;
                color: #fff;
              }
            }
          }
        }
      }
    }
    ul {
      li {
        @extend %body2;
        color: #fff;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.for_snapchat {
  margin-bottom: 238px;
  color: #fff;
  p {
    &:first-child {
      @extend %h4;
      margin-bottom: 32px;
      &+p {
        @extend %body1;
        color: #fff;
        margin-bottom: 35px;
        max-width: 784px;
        &+a {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          @extend %navigation_font;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}


.contact_wrapper {
  color: #fff;
  .contact_title {
    margin-top: 88px;
    @extend %h3;
    margin-bottom: 35px;
    &+p {
      @extend %body1;
    }
  }

  .for_contact_form {
    margin-top: 88px;
    width: 100%;
    max-width: 560px;
    margin-left: auto;
    label {
      width: 100%;
      @extend %body3;
      color: $gray1;
      span {
        display: block;
        margin-top: 16px;
        margin-bottom: 32px;
      }
    }

    input[type=text],
    input[type=email],
    input[type=password] {
      box-sizing: border-box;
      width: 100%;
      max-width: 560px;
      height: 52px;
      display: block;
      background-color: $black2;
      border: none;
      padding-left: 24px;
      outline: none;
    }

    button {
      @extend %navigation_font;
      border: none;
      outline: none;
      background-color: transparent;
      color: #fff;
      margin-left: auto;
      display: block;
    }
  }
}

.wpcf7-not-valid-tip {
  &+.wpcf7-not-valid-tip {
    display: none!important;
  }
}


.register_wrapper {
  color: #fff;
  .profile_flex {
    display: flex;
    justify-content: space-between;
    .left {
      width: 100%;
      max-width: 500px;
      p {
        @extend %h2;
        color: #fff;
        margin-top: 88px;
      }
    }
    .right {
      width: 100%;
      max-width: 560px;
      .for_form {
        margin-top: 96px;
        label {
          text-indent: 0!important;
          position: relative!important;
          left: 0!important;
          width: 100%;
          @extend %body3;
          color: $gray1;
          margin-bottom: 13px!important;
          span {
            display: block;
            margin-top: 16px;
            margin-bottom: 32px;
          }
        }
    
        input[type=text],
        input[type=email],
        input[type=password] {
          box-sizing: border-box;
          width: 100%;
          max-width: 560px;
          height: 52px;
          display: block;
          background-color: $black2;
          border: none;
          padding-left: 24px;
          outline: none;
          margin-bottom: 32px!important;
        }
    
        button {
          @extend %navigation_font;
          border: none;
          outline: none;
          background-color: transparent;
          color: #fff;
          margin-left: auto;
          display: block;
        }
      }
    }
  }
}


.account-page {
  color: #fff;

  input[type=text],
        input[type=email],
        input[type=password] {
          box-sizing: border-box;
          width: 100%;
          max-width: 560px;
          height: 52px;
          display: block;
          background-color: $black2;
          border: none;
          padding-left: 24px!important;
          color: #fff;
          outline: none;
          margin-bottom: 32px!important;
        }
    
        button {
          @extend %navigation_font;
          border: none;
          outline: none;
          background-color: transparent;
          color: #fff;
          margin-left: auto;
          display: block;
        }

  ul.acc-header {
    display: flex;
    margin-bottom: 50px;
    margin-top: 65px;

    li {
      margin-right: 100px;
      h4 {
        // color: $secondary-text;
        // transition: $transition;

        &:hover {
          // color: $main_color;
          // transition: $transition;
        }
      }
      button {
        opacity: 0.6;
      }
      .current {
        opacity: 1;
        h4 {
          // color: $main_color;
          // transition: $transition;
        }
      }
    }
  }

  form {
    ul {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .wppb-form-field {
    opacity: 0;
    height: 0;
    padding: 0;
    max-width: 0;
  //   transition: $transition;
    margin-top: 0;

    input,
    label {
      width: 100%;
    }

    input {
      padding: 0;
    }

    &.show {
      margin-left: 15px;
      margin-right: 15px;

      &:not(.wppb-heading) {
        display: inline-block;
        // margin-top: 16px;
        width: calc(50% - 30px);
        opacity: 1;
        height: auto;
        // padding: 15px 0;
        max-width: unset;
      }

      input {
        // padding: 9px 23px;
      }
    }
  }
  .wppb-heading {
    display: none;
    width: 0;
  }
}


.woocommerce {
  color: #fff;
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button, .woocommerce-cart .wc-proceed-to-checkout a.checkout-button, .woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
  background-color: transparent!important;
  transition: 0.2s ease;
  &:hover {
    background: $main_linear
  }
}

.woocommerce-privacy-policy-text {
  color: $black2;
}
.woocommerce-checkout #order_review_heading {
  color: #fff;
}
.woocommerce form .form-row label {
  color: #fff;
}


.doc_banner {
  // height: 560px;
  // background: linear-gradient(180deg, #0000FF 0%, rgba(0, 0, 255, 0) 100%);
  // padding-top: 200px;
  .doc_title {
    @extend %h2;
    color: #fff;
    margin-top: 112px;
    margin-bottom: 255px;
  }
}

.doc_content {
  // margin-top: -151px;
  margin-bottom: 102px;
  .other_doc {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      margin-left: 10px;
    }
    margin-top: 30px;
    display: inline-block;
    @extend %body1;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      width: 0%;
      transition: 0.2s ease-in-out;
      
    }
    &:hover {
      &::after {
        width: 40%;
      }
    }
    color: #fff;
    text-decoration: none;
  }
  .doc_content-block {
    b, strong {
      font-weight: bold;
    }

    table tr, td{
        border: 1px solid #fff;
        padding: 5px 10px;
    }
    table {
        margin: 20px 0px;
    }
    @extend %body2;
    color: #fff;
    a {
      color: #fff;
      text-decoration: none;
      position: relative;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: $border_linear;
        position: absolute;
        left: 0;
        bottom: -1px;
      }
    }
    p,ul,ol {
      margin: 10px 0px;
      li {
        margin: 7px 0px;
      }
    }
    ul,ol {
      margin-left: 30px;
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }

    h2 {
      @extend %h5;
      margin: 25px 0px;
    }
  }
}


.for_faq_canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2000px;
  z-index: -1;
}
.for_pricing_canvas {
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 1000px;
}

.for_canvas_form_page {
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 700px;
}

.wpcf7-spinner {
  opacity: 0!important;
  &:nth-child(2) {
    opacity: 1!important;
  }
}


.contact_wrapper .for_contact_form button {
  margin-top: 20px;
  svg {
    margin-left: 10px;
  }
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background-color: #16161A;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
}


.login-register-lost-password {
  a {
    color: #fff;
  }
}
.register_wrapper .profile_flex .right .for_form button {
  margin-top: 40px!important;
  margin-bottom: 30px!important;
  svg {
    margin-left: 10px;
  }
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background-color: #16161A;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
}


.register_wrapper .login-remember {
  position: relative;
  z-index: 333;
  padding-top: 10px;
  // display: none!important;
  float: left;
  input  {
    position: absolute;
    left: -999999999px;
    &:checked {
      &+label {
        &::before {
          background-color: #fff;
        }
      }
    }
  }
  label {
    padding-left: 28px!important;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #fff;
      border-radius: 4px;
      position: absolute;
      left: 0px;
      transition: 0.2s ease-in-out;
    }
  }
}

.register_wrapper .profile_flex .right .for_form .login-remember label {
  margin-bottom: 0px!important;
}

.page-id-16 .register_wrapper .profile_flex .right .for_form label span {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
}
.page-id-16 {
  .wppb-checkbox {
    display: none;
  }
}


.contact_wrapper .for_contact_form input[type=email], .contact_wrapper .for_contact_form input[type=password], .contact_wrapper .for_contact_form input[type=text] {
  color: #fff;
}

.google_policy {
  @extend %body3;
  color: $gray2;
  a {
    text-decoration: none;
    color: $gray2;
  }
}

.register_wrapper .profile_flex .right .for_form input[type=email], .register_wrapper .profile_flex .right .for_form input[type=password], .register_wrapper .profile_flex .right .for_form input[type=text] {
  color: #fff;
}

.page-id-16 .wppb-checkbox {
  cursor: pointer;
  display: block;
  input {
    position: absolute;
    left: -99999999px;
    &:checked {
      &+.custom-checkbox {
        background-color: #fff;
      }
    }
  }

  .custom-checkbox {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    left: 0;
  }

  label {
    position: relative;
    padding-left: 30px;
  }
}



.social_media_promotion {
  .inherit_title {
    @extend %h2;
    margin-bottom: 140px;
    color: #fff;
  }

  .for_circle_block {
    margin-bottom: 250px;
    position: relative;
    .snapchat {
      position: absolute;
      top: 0;
      left: 72px;
    }
    .tiktok {
      position: absolute;
      top: 243px;
      left: 0;
    }
    .inst {
      top: 491px;
      left: 80px;
    }
    .facebook_little {
      top: 619px;
      left: 263px;
    }
  }

  .little_circle {
    cursor: pointer;
    position: absolute;
    width: 144px;
    height: 140px;
    svg {
      transition: 0.2s ease;
    }
    &::after {
      content: '';
      display: block;
      width: 184px;
      height: 184px;
      border: 2px solid rgba(216, 221, 225, 0.3);
      border-radius: 50%;
      position: absolute;
      // left: 0;
      // right: 0;
      // margin: 0px auto;
      // right: 0;
      left: 50%;
      top: 50%;
      margin: 0px auto;
      transform: translateY(-50%) translateX(-50%);
      opacity: 0;
      transition: 0.35s ease-in-out;
    }

    &:hover {
      svg {
        transform: scale(1.1);
      }
      &::after {
        opacity: 1;
      }
    }
  }

  .for_circle_block {
    .container_for_little_circle {
      z-index: 3;
      width: 576px;
      height: 576px;
      // background-color: silver;
      position: absolute;
      top: 20px;
      right: 0;
      transform: rotate(0deg);
      transition: 0.2s ease-in-out;
      .circle_nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -18px;
      }
    }
    .circle {
      width: 576px;
      height: 576px;
      margin-left: auto;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-top: 100px;
      top: 20px;

      .content {
        transition: 0.2s ease-in-out;
        opacity: 0;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0px auto;
        width: 100%;
        max-width: 376px;
        text-align: center;
        p {
          &:first-child {
            @extend %h4;
            margin-bottom: 24px;
            &+p {
              @extend %body1;
            }
          }
        }
      }
      .tiktok_content {
        opacity: 1;
      }
    }
  }
}

.thank-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 48px;
  background: linear-gradient(62.89deg, #1F1F24 18.5%, #141518 46.25%, #0E1015 76.32%);
  border-radius: 24px;
  padding-top: 80px;
  padding-bottom: 104px;
  text-align: center;
  max-width: 720px;
  p {
    color: #fff;
    &:first-child {
      @extend %h2;
      margin-bottom: 39px;
      &+p {
        @extend %h5;
        color: #fff;
      }
    }
  }

  &+a {
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 10px;
    }
    @extend %navigation_font;
    color: #fff;
    text-decoration: none;
    margin-bottom: 120px;
  }
}

.error-page {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 656px;
  margin-top: 101px;
  margin-bottom: 150px;
  background: linear-gradient(62.89deg, #1F1F24 18.5%, #141518 46.25%, #0E1015 76.32%);
  border-radius: 24px;
  text-align: center;
  p {
    font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 144px;
line-height: 130%;
/* or 187px */

text-align: center;

color: #FFFFFF;

  }
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-left: 10px;
    }
    @extend %navigation_font;
    color: #fff;
    text-decoration: none;
  }
  padding-top: 45px;
  padding-bottom: 98px;
}



.how_we_work {
  .section__title {
    @extend %h3;
    margin-bottom: 120px;
  }

  .block {
    margin-bottom: 144px;
    p {
      &:first-child {
        @extend %h5;
        margin-bottom: 32px;
        &+p {
          @extend %body1;
        }
      }
    }
  }
}
// Always in the end
@import "_media.scss";
// ___________________