// show-hide password
@keyframes password-line {
  from {
    opacity: 0;
    top: 80px;
  }
  to {
    opacity: 1;
  }
}

@keyframes password-line-off {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    top: 80px;
  }
}

.show-hide,
.password-line {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
  }
}

.show-hide{
  padding: 0px;
}

.password-line {
  &.show {
    animation: password-line 0.8s;
    display: block;

    svg {
      top: 5px;
    }
  }

  &.off {
    animation: password-line-off 0.8s;
    opacity: 0;
  }
}

// end show-hide password
